import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate, useLocation } from 'react-router-dom';

const AddQuestion = () => {
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');
    const [formData, setFormData] = useState({
        question: '',
        explanation: '',
        options: [
            { text: '', isCorrect: false },
            { text: '', isCorrect: false },
            { text: '', isCorrect: false },
            { text: '', isCorrect: false },
        ],
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await apiCallFetch('/topic/get', 'GET');
                if (response.status) setTopics(response.data);
            } catch (error) {
                setError('Failed to load topics.');
            }
        };

        fetchTopics();

        if (location.state && location.state.question) {
            const questionToEdit = location.state.question;
            setFormData({
                question: questionToEdit.question,
                explanation: questionToEdit.explanation,
                options: questionToEdit.options.map(option => ({
                    text: option.text,
                    isCorrect: option.isCorrect
                })),
            });
            setSelectedTopic(questionToEdit.topic._id || questionToEdit.topic);
        }
    }, [location.state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionChange = (index, field, value) => {
        setFormData((prev) => {
            const updatedOptions = prev.options.map((opt, idx) =>
                idx === index ? { ...opt, [field]: value } : opt
            );
            if (field === 'isCorrect') {
                updatedOptions.forEach((opt, idx) => (opt.isCorrect = idx === index));
            }
            return { ...prev, options: updatedOptions };
        });
    };

    const validateForm = () => {
        const { question, options } = formData;
        if (!question.trim() || !selectedTopic) {
            setError('Please complete all fields.');
            return false;
        }
        if (options.every((opt) => !opt.text.trim())) {
            setError('Each option must have text.');
            return false;
        }
        if (!options.some((opt) => opt.isCorrect)) {
            setError('Please mark one correct answer.');
            return false;
        }
        setError(null);
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const newQuestion = {
            ...formData,
            difficulty: 'medium',
            topic: selectedTopic,
        };

        try {
            const endpoint = location.state && location.state.question
                ? `/questions/update/${location.state.question._id}`
                : '/questions/create';
            const method = location.state && location.state.question ? 'PUT' : 'POST';

            await apiCallFetch(endpoint, method, newQuestion);
            navigate('/question-master');
        } catch (error) {
            setError('Failed to add or update question.');
        }
    };

    return (
        <div className="bg-white shadow-xl rounded-lg container mx-auto max-w-2xl px-6 py-8">
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-gray-700 text-2xl mr-3"
                    onClick={() => navigate('/question-master')}
                >
                    ←
                </button>
                <h1 className="text-3xl font-semibold">
                    {location.state && location.state.question ? 'Update Question' : 'Add Question'}
                </h1>
            </div>
            {error && <p className="bg-red-100 text-red-600 border border-red-300 p-3 rounded mb-6">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Question</label>
                    <input
                        type="text"
                        name="question"
                        value={formData.question}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>
                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Explanation</label>
                    <textarea
                        name="explanation"
                        value={formData.explanation}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        rows="3"
                    />
                </div>
                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Select Topic</label>
                    <select
                        value={selectedTopic || ''}
                        onChange={(e) => setSelectedTopic(e.target.value)}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    >
                        <option value="">Select a topic</option>
                        {topics.map((topic) => (
                            <option key={topic._id} value={topic._id}>
                                {topic.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-6">
                    <label className="block text-lg font-medium mb-3">Options</label>
                    {formData.options.map((option, index) => (
                        <div key={index} className="flex items-center mb-2">
                            <input
                                type="text"
                                value={option.text}
                                onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
                                className="border-gray-300 border p-3 rounded-md w-full mr-3 focus:outline-blue-500"
                                placeholder={`Option ${index + 1}`}
                                required
                            />
                            <label className="flex items-center ml-3">
                                <input
                                    type="radio"
                                    checked={option.isCorrect}
                                    onChange={() => handleOptionChange(index, 'isCorrect', true)}
                                    className="mr-1 accent-blue-500"
                                />
                                Correct
                            </label>
                        </div>
                    ))}
                </div>
                <button type="submit" className="bg-blue-500 text-white px-6 py-3 rounded-md w-full"
                >
                    {location.state && location.state.question ? 'Update Question' : 'Add Question'}
                </button>
            </form>
        </div>
    );
};

export default AddQuestion;
