// import React from 'react';

// const CompanyDashboard = () => {
//     return (
//         <div>
//             <h1>Company Dashboard</h1>
//             {/* Add content for company dashboard */}
//         </div>
//     );
// };

// export default CompanyDashboard;



import React from 'react';

const CompanyDashboard = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f6f9', minHeight: '100vh' }}>
            <h1 style={{ color: '#3f51b5', fontSize: '32px', fontWeight: 'bold', marginBottom: '20px' }}>
                Company Dashboard
            </h1>

            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {/* Business Overview Card */}
                <div style={cardStyle}>
                    <div style={iconStyle}>🏢</div>
                    <h2 style={titleStyle}>Business Overview</h2>
                    <p style={textStyle}>Track your company's performance, earnings, and statistics.</p>
                    <button style={buttonStyle}>View Details</button>
                </div>

                {/* Teams & Employees Card */}
                <div style={cardStyle}>
                    <div style={iconStyle}>👥</div>
                    <h2 style={titleStyle}>Teams & Employees</h2>
                    <p style={textStyle}>Manage your teams and employees' activities.</p>
                    <button style={buttonStyle}>Manage Teams</button>
                </div>

                {/* Global Referrals Card */}
                <div style={cardStyle}>
                    <div style={iconStyle}>🌐</div>
                    <h2 style={titleStyle}>Global Gives</h2>
                    <p style={textStyle}>View and manage all global Gives for your company.</p>
                    <button style={buttonStyle}>View Gives</button>
                </div>

                {/* Analytics & Reports Card */}
                <div style={cardStyle}>
                    <div style={iconStyle}>📊</div>
                    <h2 style={titleStyle}>Analytics & Reports</h2>
                    <p style={textStyle}>Analyze your data and generate reports.</p>
                    <button style={buttonStyle}>Generate Reports</button>
                </div>
            </div>
        </div>
    );
};

// CSS styles as JavaScript objects
const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '8px',
    width: 'calc(25% - 10px)',
    marginBottom: '20px',
    textAlign: 'center',
    flex: '1 1 200px',
};

const iconStyle = {
    fontSize: '40px',
    marginBottom: '10px',
    color: '#3f51b5',
};

const titleStyle = {
    fontSize: '20px',
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '10px',
};

const textStyle = {
    fontSize: '16px',
    color: '#666',
    marginBottom: '15px',
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
};

export default CompanyDashboard;
