import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import { apiCallFetch } from '../api/apiHelper';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Toast from '../components/Toast';

const Login = () => {
    const [toast, setToast] = useState({ message: '', type: '', show: false });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [values, setValues] = useState({ email: '', password: '' });
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(''); // Reset error

        // Validate form input
        if (!values.email || !values.password) {
            const errorMessage = 'Please fill out both fields.';
            setError(errorMessage);
            setToast({ message: errorMessage, type: 'error', show: true });
            setLoading(false);
            return;
        }

        if (!validateEmail(values.email)) {
            const errorMessage = 'Invalid email format.';
            setError(errorMessage);
            setToast({ message: errorMessage, type: 'error', show: true });
            setLoading(false);
            return;
        }

        try {
            const response = await apiCallFetch('/users/loginuser', 'POST', values, null);

            if (response.status) {
                // Store user data and redirect based on the role
                if (response.data.role === 'company') {
                    localStorage.setItem('company', JSON.stringify(response.data));
                    setToast({ message: response.message, type: 'success', show: true });
                    navigate('/company-dashboard');
                } else if (response.data.role === 'staff' || response.data.role === 'mentee' || response.data.role === 'mentor') {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    setToast({ message: response.message, type: 'success', show: true });
                    navigate('/user-dashboard');
                } else if (response.data.role === 'admin') {
                    localStorage.setItem('admin', JSON.stringify(response.data));
                    setToast({ message: response.message, type: 'success', show: true });
                    navigate('/admin-dashboard');
                }
            } else {
                const errorMessage = response.message || 'Login failed. Please check your credentials.';
                setError(errorMessage);
                setToast({ message: errorMessage, type: 'error', show: true });
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Login failed. Please try again.';
            setError(errorMessage);
            setToast({ message: errorMessage, type: 'error', show: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg">
                    <h2 className="text-center text-3xl font-bold">Login</h2>

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="w-full p-2 mt-1 border rounded"
                                placeholder="Enter your email"
                                onChange={(e) => setValues(prev => ({ ...prev, email: e.target.value }))} />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    className="w-full p-2 mt-1 border rounded"
                                    placeholder="Enter your password"
                                    onChange={(e) => setValues(prev => ({ ...prev, password: e.target.value }))} />
                                <div
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </div>
                            </div>
                        </div>

                        {/* Only display error message once */}
                        {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}

                        <div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
                                disabled={loading}
                            >
                                {loading ? 'Logging in...' : 'Login'}
                            </button>
                        </div>
                    </form>

                    {/* Forgot Password Link */}
                    <div className="text-center mt-4">
                        <Link to="/forgot-password" className="text-blue-500 hover:underline">
                            Forgot Password?
                        </Link>
                    </div>
                </div>
            </div>

            {/* Toast Component */}
            {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />}
        </div>
    );
};

export default Login;

