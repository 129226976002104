import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Toast from '../components/Toast';

const CompanyLogin = () => {
    const [toast, setToast] = useState({ message: '', type: '', show: false });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required'),
    });

    // const handleLogin = async (values, { setSubmitting }) => {
    //     setLoading(true);
    //     setError('');
    //     try {
    //         const response = await apiCallFetch('/company/login', 'POST', values, null);
    //         if (response.status) {
    //             localStorage.setItem('company', JSON.stringify(response.data));
    //             setIsCompanyAuthenticated(true);
    //             setToast({ message: 'Login successful!', type: 'success', show: true });
    //             setTimeout(() => {
    //                 navigate('/company-dashboard');
    //             }, [1500])
    //         } else {
    //             setError(response.message);
    //             setToast({ message: response.message, type: 'error', show: true });
    //             navigate('/company-login');
    //         }
    //     } catch (err) {
    //         setError(err.response?.data?.message || 'Login failed. Please try again.');
    //         setToast({ message: 'Login failed. Please try again.', type: 'error', show: true });
    //         navigate('/company-login');
    //     } finally {
    //         setLoading(false);
    //         setSubmitting(false);
    //     }
    // };

    const handleLogin = async (values, { setSubmitting }) => {
        setLoading(true);
        setError('');
        try {
            const response = await apiCallFetch('/company/login', 'POST', values, null);
            if (response.status) {
                localStorage.setItem('company', JSON.stringify(response.data));
                // setIsCompanyAuthenticated(true);
                setToast({ message: 'Login successful!', type: 'success', show: true });
                setTimeout(() => {
                    navigate('/company-dashboard');
                }, 1500);
            } else {
                setError(response.message);
                setToast({ message: response.message, type: 'error', show: true });
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Login failed. Please try again.');
            setToast({ message: 'Login failed. Please try again.', type: 'error', show: true });
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg">
                    <h2 className="text-center text-3xl font-bold">Company Login</h2>

                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleLogin(values, { setSubmitting });
                        }}
                    >
                        {({ isSubmitting, handleSubmit }) => (
                            <Form className="space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Email</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        className="w-full p-2 mt-1 border rounded"
                                        placeholder="Enter your email"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Password</label>
                                    <div className="relative">
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            className="w-full p-2 mt-1 border rounded"
                                            placeholder="Enter your password"
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                        </div>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                                </div>

                                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                                <div>
                                    <button
                                        type="submit"
                                        className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
                                        disabled={isSubmitting || loading}
                                    >
                                        {loading ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />}
        </div>
    );
};

export default CompanyLogin;
