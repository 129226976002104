import React, { useEffect, useState } from "react";
import { FaUsers, FaUserFriends, FaCrown } from "react-icons/fa"; // Import icons from react-icons
import { apiCallFetch } from "../api/apiHelper";
import { useNavigate } from "react-router-dom";

const LeaderboardDashboard = ({ dashboardData }) => {
  const navigate = useNavigate();

  return (
    <div
      className="min-h-screen bg-olive-green bg-contain bg-no-repeat bg-center bg-blend-overlay p-4"
      style={{
        backgroundImage: "url('/o.png')",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      {/* Header */}
      <div className="flex items-center my-6">
        <h1 className="text-white text-2xl font-semibold mx-auto ">
          Leaderboard
        </h1>
      </div>

      {/* Toggle */}
      {/* <div className="bg-[#6068aa] rounded-full p-1 flex mb-4 mx-auto max-w-xs">
        <button className="flex-1 py-2 px-4 rounded-full bg-white text-[#24284c]">
          Weekly
        </button>
        <button className="flex-1 py-2 px-4 rounded-full text-indigo-200">
          All Time
        </button>
      </div> */}

      {/* Status Card */}
      {/* <div className="bg-orange-300 rounded-xl p-2 mb-8 mx-2">
        <div className="flex items-center">
          <div className="bg-orange-400 rounded-xl p-3 mr-4">
            <span className="text-white font-bold text-xl">
              #{dashboardData.rank}
            </span>
          </div>
          <p className="text-white text-sm font-semibold">
            You are doing better than {dashboardData.playerPercentage}% of other
            players!
          </p>
        </div>
      </div> */}

      {/* Podium */}
      <div className="relative h-80 w-80 mt-16 m-auto flex items-end justify-center">
        {/* Second Place */}
        {dashboardData.topContributors[1] && (
          <div className="w-1/3">
            <div className="relative flex flex-col items-center">
              <div className="w-12 h-12 mb-2">
                <img
                  src={
                    dashboardData.topContributors[1].image ||
                    "/user-placeholder.jpeg"
                  }
                  alt="Second place"
                  className="w-full h-full rounded-full bg-pink-100"
                />
              </div>
              <p className="text-white text-xs text-center mb-2">
                {dashboardData.topContributors[1].name}
              </p>
              <p className="text-indigo-200 text-xs font-semibold mb-2">
                {dashboardData.topContributors[1].referralCount} GV
              </p>
              <div
                className="w-full h-40 bg-cover bg-no-repeat bg-top bg-blend-overlay"
                style={{
                  backgroundImage: "url('/2.png')",
                }}
              ></div>
            </div>
          </div>
        )}

        {/* First Place */}
        {dashboardData.topContributors[0] && (
          <div className="w-1/3">
            <div className="relative flex flex-col items-center">
              <div className="absolute -top-6">
                <FaCrown className="text-yellow-400 text-3xl" />
              </div>
              <div className="w-12 h-12 mb-2">
                <img
                  src={
                    dashboardData.topContributors[0].image ||
                    "/user-placeholder.jpeg"
                  }
                  alt="First place"
                  className="w-full h-full rounded-full bg-blue-100"
                />
              </div>
              <p className="text-white text-sm text-center mb-2">
                {dashboardData.topContributors[0].name}
              </p>
              <p className="text-indigo-200 text-xs font-semibold mb-2">
                {dashboardData.topContributors[0].referralCount} GV
              </p>
              <div
                className="w-full h-52 bg-cover bg-no-repeat bg-top bg-blend-overlay"
                style={{
                  backgroundImage: "url('/1.png')",
                }}
              ></div>
            </div>
          </div>
        )}

        {/* Third Place */}
        {dashboardData.topContributors[2] && (
          <div className="w-1/3">
            <div className="relative flex flex-col items-center">
              <div className="w-12 h-12 mb-2">
                <img
                  src={
                    dashboardData.topContributors[2].image ||
                    "/user-placeholder.jpeg"
                  }
                  alt="Third place"
                  className="w-full h-full rounded-full bg-blue-100"
                />
              </div>
              <p className="text-white text-xs text-center mb-2">
                {dashboardData.topContributors[2].name}
              </p>
              <p className="text-indigo-200 text-xs font-semibold mb-2">
                {dashboardData.topContributors[2].referralCount} GV
              </p>
              <div
                className="w-full h-32 bg-cover bg-no-repeat bg-top bg-blend-overlay"
                style={{
                  backgroundImage: "url('/3.png')",
                }}
              ></div>
            </div>
          </div>
        )}
      </div>

      {/* List of other players */}
      <div className="bg-white rounded-3xl p-4">
        <div className="space-y-4">
          {dashboardData.topContributors.slice(3, 10).map((player, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="w-8 h-8 flex items-center justify-center text-gray-400">
                {index + 4}
              </div>
              <div className="w-12 h-12 relative">
                <img
                  src={player.image || "/user-placeholder.jpeg"}
                  alt={player.name}
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="flex-1">
                <p className="font-semibold">{player.name}</p>
                <p className="text-gray-500 text-sm">
                  {player.referralCount} Gives
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-3">
          <button
            className="px-4 py-2 w-full bg-olive-green text-white rounded-full"
            onClick={() => navigate("/top-contributors")}
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    totalCompanyReferrals: 0,
    myReferrals: 0,
    topContributors: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const user = localStorage.getItem("user");
        if (!user) {
          navigate("/login");
          return;
        }

        const response = await apiCallFetch("/users/get-dashboard", "GET");
        if (response.status) {
          // Assuming response.data contains the leaderboard data in the required structure
          const {
            totalCompanyReferrals,
            myReferrals,
            topContributors,
            userRank,
            playerPercentage,
            topPlayers,
            otherPlayers,
          } = response.data;
          setDashboardData({
            totalCompanyReferrals,
            myReferrals,
            topContributors,
            rank: userRank,
            playerPercentage,
            topPlayers,
            otherPlayers,
          });
        } else {
          setError(response.message);
        }
      } catch (err) {
        console.error("Failed to fetch dashboard data", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
          <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="">
      <LeaderboardDashboard dashboardData={dashboardData} />
    </div>
  );
};

export default Dashboard;
