import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { apiCallFetch } from '../api/apiHelper';

const ChangePasswordModal = ({ onClose, onPasswordChange, setToast }) => {
    const [loading, setLoading] = useState(false);


    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('New password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            const response = await apiCallFetch('/users/change-password', 'PUT', {
                newPassword: values.newPassword
            });
            console.log(response);
            localStorage.setItem('user', JSON.stringify(response.data))
            setToast({ message: response.message, type: 'success', show: true });
            onPasswordChange(values.newPassword);
            resetForm();
            onClose();
            setTimeout(() => {
                setToast({ message: response.message, type: 'success', show: false });
            }, [1500])
        } catch (error) {
            setToast({ message: error.message || 'Failed to change password. Please try again.', type: 'error', show: true }); // Set error toast state

        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded shadow-lg w-96">
                <h2 className="text-xl font-bold mb-4">Change Password</h2>

                <Formik
                    initialValues={{ newPassword: '', confirmPassword: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-2">
                                <Field
                                    type="password"
                                    name="newPassword"
                                    placeholder="New Password"
                                    className="border p-2 w-full"
                                />
                                <ErrorMessage name="newPassword" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            <div className="mb-4">
                                <Field
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    className="border p-2 w-full"
                                />
                                <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            <button
                                type="submit"
                                className={`bg-blue-500 text-white py-2 px-4 rounded ${isSubmitting || loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isSubmitting || loading}
                            >
                                {loading ? 'Changing...' : 'Change Password'}
                            </button>

                            <button
                                type="button"
                                onClick={onClose}
                                className="ml-2 py-2 px-4 rounded border"
                            >
                                Cancel
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ChangePasswordModal;

