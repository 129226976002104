import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';

const AddQuizTopic = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const isEditMode = !!location.state?.topic; // Check if in edit mode
    const topic = location.state?.topic;

    useEffect(() => {
        if (isEditMode) {
            setName(topic.name);
            setDescription(topic.description);
        }
    }, [isEditMode, topic]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const endpoint = isEditMode ? '/topic/update' : '/topic/create';
            const method = isEditMode ? 'PUT' : 'POST';
            const requestBody = isEditMode ? { id: topic._id, name, description } : { name, description };
            const response = await apiCallFetch(endpoint, method, requestBody);

            if (response.status) {
                const message = isEditMode ? 'Quiz topic updated successfully!' : 'Quiz topic created successfully!';
                setSuccessMessage(message);
                setTimeout(() => {
                    setSuccessMessage('');
                    navigate('/quiz-topic');
                }, 2000);
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Error processing request: ' + (err?.response?.data?.message || 'An unknown error occurred'));
            console.error('Error details:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <button
                type="button"
                className="text-blue-500 text-3xl mr-2"
                onClick={() => navigate(-1)}
            >
                ←
            </button>
            <span className="text-2xl font-bold">{isEditMode ? 'Update Quiz Topic' : 'Add Quiz Topic'}</span>

            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-xl shadow-lg mt-4">
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Topic Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="border p-2 w-full"
                    />
                </div>
                {/* <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        className="border p-2 w-full"
                    ></textarea>
                </div> */}
                <button type="submit" disabled={loading} className="bg-blue-500 text-white p-2 rounded w-full">
                    {isEditMode ? 'Update Topic' : 'Add Topic'}
                </button>
            </form>
        </div>
    );
};

export default AddQuizTopic;
