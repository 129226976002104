// import React, { useEffect, useState } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { apiCallFetch } from '../api/apiHelper';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { City, State } from 'country-state-city';

// const AddReferral = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [categories, setCategories] = useState([]);
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [allStates, setAllStates] = useState([]);
//     const [allCity, setAllCity] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [isEditMode, setIsEditMode] = useState(false);
//     const [successMessage, setSuccessMessage] = useState('');

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const response = await apiCallFetch('/categories/get', 'GET');
//                 setCategories(response.data);
//             } catch (error) {
//                 console.error('Error fetching categories:', error);
//             }
//         };

//         const getStates = () => {
//             const getAllStates = State.getStatesOfCountry('IN').map(state => ({
//                 value: state.isoCode, // This will hold the state ISO code
//                 displayValue: `${state.name} - ${state.isoCode}` // Displaying name and code together
//             }));
//             setAllStates(getAllStates);
//         };

//         if (location?.state?.ref) {
//             const { state: refState, city: refCity } = location.state.ref;
//             getCity(refState, refCity); // Fetch cities for the selected state and set the city
//             setIsEditMode(true);
//             setSelectedCategory(location.state.ref.category?._id);
//         } else {
//             getCity('Gujarat - GJ', 'Ahmedabad');  // Default: Gujarat and Ahmedabad
//         }

//         fetchCategories();
//         getStates();
//     }, [location.state]);

//     const getCity = (state, selectedCity = '') => {
//         const stateCode = state.split(' - ')[1] || 'GJ'; // Split to get the ISO code
//         const getAllCities = City.getCitiesOfState('IN', stateCode).map(city => ({
//             value: city.name,
//             displayValue: city.name
//         }));
//         setAllCity(getAllCities);

//         if (selectedCity) {
//             formik.setFieldValue('city', selectedCity); // Set the city if we're in update mode
//         } else {
//             formik.setFieldValue('city', getAllCities[0]?.value); // Default to the first city in the list
//         }
//     };

//     const formik = useFormik({
//         initialValues: {
//             name: location?.state?.ref?.name || '',
//             category: location?.state?.ref?.category?._id || '',
//             department: location?.state?.ref?.department || '',
//             company: location?.state?.ref?.company || '',
//             state: location?.state?.ref?.state || 'Gujarat - GJ',  // Default state with code
//             city: location?.state?.ref?.city || 'Ahmedabad', // Default city
//             phone: location?.state?.ref?.phone || '',
//             email: location?.state?.ref?.email || '',
//         },
//         validationSchema: Yup.object({
//             name: Yup.string().required('Required'),
//             category: Yup.string().required('Required'),
//             department: Yup.string().nullable(), // Optional
//             company: Yup.string().nullable(), // Optional
//             state: Yup.string().required('Required'),
//             city: Yup.string().required('Required'),
//             phone: Yup.string()
//                 .matches(/^[0-9]+$/, 'Must be only digits')
//                 .min(7, 'Must be at least 7 digits')
//                 .max(15, 'Must be 15 digits or less'),
//             email: Yup.string().email('Invalid email address'),
//         }),
//         onSubmit: async (values) => {
//             setLoading(true);
//             try {
//                 const endpoint = isEditMode ? '/referrals/update' : '/referrals/create';
//                 const method = isEditMode ? 'PUT' : 'POST';

//                 const requestBody = {
//                     referralId: isEditMode ? location?.state?.ref?._id : undefined,
//                     ...values,
//                 };

//                 console.log(`Calling API endpoint: ${endpoint}`, requestBody);
//                 const response = await apiCallFetch(endpoint, method, requestBody);
//                 console.log('API Response:', response);

//                 if (response.status) {
//                     formik.resetForm();
//                     setSuccessMessage(isEditMode ? 'Give updated successfully!' : 'Give added successfully!');

//                     setTimeout(() => {
//                         setSuccessMessage('');
//                         navigate('/my-referrals');
//                     }, 2000);
//                 } else {
//                     console.error('Error response from API:', response);
//                 }
//             } catch (err) {
//                 console.error('Error submitting referral:', err);
//             } finally {
//                 setLoading(false);
//             }
//         }
//     });

//     return (
//         <div className="p-6 max-w-md mx-auto">
//             <div className="flex items-center mb-4">
//                 <button
//                     type="button"
//                     className="text-blue-500 text-3xl mr-2"
//                     onClick={() => navigate(-1)}
//                 >
//                     ←
//                 </button>
//                 <h1 className="text-2xl font-bold">{isEditMode ? 'Update Give' : 'Add Give'}</h1>
//             </div>

//             {successMessage && (
//                 <div className="bg-green-100 text-green-700 p-2 rounded mb-4">
//                     {successMessage}
//                 </div>
//             )}

//             <form onSubmit={formik.handleSubmit}>
//                 <input
//                     type="text"
//                     name="name"
//                     value={formik.values.name}
//                     onChange={formik.handleChange}
//                     placeholder="Name"
//                     className="border p-2 w-full mb-2"
//                 />
//                 {formik.errors.name && <div className="text-red-500 text-xs mb-2">{formik.errors.name}</div>}

//                 <select
//                     value={selectedCategory}
//                     onChange={(e) => {
//                         setSelectedCategory(e.target.value);
//                         formik.setFieldValue('category', e.target.value);
//                     }}
//                     className="border p-2 mb-2 w-full"
//                 >
//                     <option value="">All Categories</option>
//                     {categories.map((category, i) => (
//                         <option value={category._id} key={i}>{category.name}</option>
//                     ))}
//                 </select>
//                 {formik.errors.category && <div className="text-red-500 text-xs mb-2">{formik.errors.category}</div>}

//                 <input
//                     type="text"
//                     name="department"
//                     value={formik.values.department}
//                     onChange={formik.handleChange}
//                     placeholder="Department (Optional)"
//                     className="border p-2 w-full mb-2"
//                 />
//                 {formik.errors.department && <div className="text-red-500 text-xs mb-2">{formik.errors.department}</div>}

//                 <input
//                     type="text"
//                     name="company"
//                     value={formik.values.company}
//                     onChange={formik.handleChange}
//                     placeholder="Company (Optional)"
//                     className="border p-2 w-full mb-2"
//                 />
//                 {formik.errors.company && <div className="text-red-500 text-xs mb-2">{formik.errors.company}</div>}

//                 <select
//                     name="state"
//                     value={formik.values.state}
//                     onChange={(e) => {
//                         formik.setFieldValue('state', e.target.value);
//                         getCity(e.target.value);  // Fetch cities for the selected state
//                     }}
//                     className="border p-2 mb-2 w-full"
//                 >
//                     {allStates.map((state, index) => (
//                         <option value={state.displayValue} key={index}>{state.displayValue}</option>
//                     ))}
//                 </select>
//                 {formik.errors.state && <div className="text-red-500 text-xs mb-2">{formik.errors.state}</div>}

//                 <select
//                     name="city"
//                     value={formik.values.city}
//                     onChange={formik.handleChange}
//                     className="border p-2 mb-2 w-full"
//                 >
//                     {allCity.map((city, index) => (
//                         <option value={city.value} key={index}>{city.displayValue}</option>
//                     ))}
//                 </select>
//                 {formik.errors.city && <div className="text-red-500 text-xs mb-2">{formik.errors.city}</div>}

//                 <input
//                     type="text"
//                     name="phone"
//                     value={formik.values.phone}
//                     onChange={formik.handleChange}
//                     placeholder="Phone"
//                     className="border p-2 w-full mb-2"
//                 />
//                 {formik.errors.phone && <div className="text-red-500 text-xs mb-2">{formik.errors.phone}</div>}

//                 <input
//                     type="text"
//                     name="email"
//                     value={formik.values.email}
//                     onChange={formik.handleChange}
//                     placeholder="Email"
//                     className="border p-2 w-full mb-2"
//                 />
//                 {formik.errors.email && <div className="text-red-500 text-xs mb-2">{formik.errors.email}</div>}

//                 <button type="submit" disabled={loading} className="bg-blue-500 text-white p-2 rounded w-full">
//                     {isEditMode ? 'Update Give' : 'Add Give'}
//                 </button>
//             </form>
//         </div>
//     );
// };

// export default AddReferral;


import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiCallFetch } from '../api/apiHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { City, State } from 'country-state-city';

const AddReferral = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [allStates, setAllStates] = useState([]);
    const [allCity, setAllCity] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await apiCallFetch('/categories/get', 'GET');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const getStates = () => {
            const getAllStates = State.getStatesOfCountry('IN').map(state => ({
                value: state.isoCode,
                displayValue: `${state.name} - ${state.isoCode}`
            }));
            setAllStates(getAllStates);
        };

        if (location?.state?.ref) {
            const { state: refState, city: refCity } = location.state.ref;
            getCity(refState, refCity);
            setIsEditMode(true);
            setSelectedCategory(location.state.ref.category?._id);
        } else {
            getCity('Gujarat - GJ', 'Ahmedabad');
        }

        fetchCategories();
        getStates();
    }, [location.state]);

    const getCity = (state, selectedCity = '') => {
        const stateCode = state.split(' - ')[1] || 'GJ';
        const getAllCities = City.getCitiesOfState('IN', stateCode).map(city => ({
            value: city.name,
            displayValue: city.name
        }));
        setAllCity(getAllCities);

        if (selectedCity) {
            formik.setFieldValue('city', selectedCity);
        } else {
            formik.setFieldValue('city', getAllCities[0]?.value);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: location?.state?.ref?.name || '',
            category: location?.state?.ref?.category?._id || '',
            department: location?.state?.ref?.department || '',
            company: location?.state?.ref?.company || '',
            state: location?.state?.ref?.state || 'Gujarat - GJ',
            city: location?.state?.ref?.city || 'Ahmedabad',
            phone: location?.state?.ref?.phone || '',
            email: location?.state?.ref?.email || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            category: Yup.string().required('Required'),
            department: Yup.string().nullable(),
            company: Yup.string().nullable(),
            state: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            phone: Yup.string().matches(/^[0-9]+$/, 'Must be only digits').min(7, 'Must be at least 7 digits').max(15, 'Must be 15 digits or less'),
            email: Yup.string().email('Invalid email address'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const endpoint = isEditMode ? '/referrals/update' : '/referrals/create';
                const method = isEditMode ? 'PUT' : 'POST';

                const requestBody = {
                    referralId: isEditMode ? location?.state?.ref?._id : undefined,
                    ...values,
                };

                const response = await apiCallFetch(endpoint, method, requestBody);

                if (response.status) {
                    formik.resetForm();
                    setSuccessMessage(isEditMode ? 'Give updated successfully!' : 'Give added successfully!');
                    setTimeout(() => {
                        setSuccessMessage('');
                        navigate('/my-referrals');
                    }, 2000);
                }
            } catch (err) {
                console.error('Error submitting referral:', err);
            } finally {
                setLoading(false);
            }
        },
        validateOnBlur: false, // Prevent showing validation errors on blur
        validateOnChange: false, // Prevent showing validation errors on change
    });

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-lg">
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-blue-500 text-xl mr-4"
                    onClick={() => navigate(-1)}
                >
                    ←
                </button>
                <h1 className="text-3xl font-semibold">{isEditMode ? 'Update Give' : 'Add Give'}</h1>
            </div>

            {successMessage && (
                <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-6">
                    {successMessage}
                </div>
            )}

            <form onSubmit={formik.handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Name</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Name"
                    />
                    {formik.errors.name && formik.touched.name && <div className="text-red-500 text-sm">{formik.errors.name}</div>}
                </div>

                <div>
                    <label htmlFor="category" className="block text-gray-700 font-medium mb-2">Category</label>
                    <select
                        id="category"
                        value={selectedCategory}
                        onChange={(e) => {
                            setSelectedCategory(e.target.value);
                            formik.setFieldValue('category', e.target.value);
                        }}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                            <option value={category._id} key={category._id}>{category.name}</option>
                        ))}
                    </select>
                    {formik.errors.category && formik.touched.category && <div className="text-red-500 text-sm">{formik.errors.category}</div>}
                </div>

                <div>
                    <label htmlFor="department" className="block text-gray-700 font-medium mb-2">Department (Optional)</label>
                    <input
                        id="department"
                        type="text"
                        name="department"
                        value={formik.values.department}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Department"
                    />
                    {formik.errors.department && <div className="text-red-500 text-sm">{formik.errors.department}</div>}
                </div>

                <div>
                    <label htmlFor="company" className="block text-gray-700 font-medium mb-2">Company (Optional)</label>
                    <input
                        id="company"
                        type="text"
                        name="company"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Company"
                    />
                    {formik.errors.company && <div className="text-red-500 text-sm">{formik.errors.company}</div>}
                </div>

                <div>
                    <label htmlFor="state" className="block text-gray-700 font-medium mb-2">State</label>
                    <select
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => {
                            formik.setFieldValue('state', e.target.value);
                            getCity(e.target.value);
                        }}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {allStates.map((state) => (
                            <option value={state.displayValue} key={state.value}>{state.displayValue}</option>
                        ))}
                    </select>
                    {formik.errors.state && <div className="text-red-500 text-sm">{formik.errors.state}</div>}
                </div>

                <div>
                    <label htmlFor="city" className="block text-gray-700 font-medium mb-2">City</label>
                    <select
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        {allCity.map((city) => (
                            <option value={city.value} key={city.value}>{city.displayValue}</option>
                        ))}
                    </select>
                    {formik.errors.city && <div className="text-red-500 text-sm">{formik.errors.city}</div>}
                </div>

                <div>
                    <label htmlFor="phone" className="block text-gray-700 font-medium mb-2">Phone</label>
                    <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Phone"
                    />
                    {formik.errors.phone && <div className="text-red-500 text-sm">{formik.errors.phone}</div>}
                </div>

                <div>
                    <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className="w-full border p-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter Email"
                    />
                    {formik.errors.email && <div className="text-red-500 text-sm">{formik.errors.email}</div>}
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full py-3 px-6 mt-6 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-400"
                >
                    {loading ? 'Submitting...' : 'Submit Give'}
                </button>
            </form>
        </div>
    );
};

export default AddReferral;
