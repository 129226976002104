import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaBuilding, FaCity, FaEnvelope, FaPhone } from 'react-icons/fa';
import { apiCallFetch, baseURL } from '../api/apiHelper';

const GlobalReferrals = () => {  // Add isCompany as a prop
    const [modalOpen, setModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [err, setErr] = useState('');
    const [currentReferral, setCurrentReferral] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        department: '',
        company: '',
        city: '',
        state: '',
        phone: '',
        email: '',
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [referrals, setReferrals] = useState([]);
    const [interestedCards, setInterestedCards] = useState({});
    const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 });
    const [hasMore, setHasMore] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);

    const ITEMS_PER_PAGE = 10;

    const observer = useRef();

    const lastReferralElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPagination(prev => ({ ...prev, page: prev.page + 1 }));
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const LoadingSpinner = ({ className }) => (
        <div className={`flex justify-center ${className}`}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    const fetchCategories = async () => {
        try {
            const response = await apiCallFetch('/categories/get', 'GET');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        setPagination(prev => ({ ...prev, page: 1 }));
        setReferrals([]);
        setHasMore(true);
        fetchReferrals(1, false);
    }, [searchTerm, selectedCategory]);

    useEffect(() => {
        fetchCategories();
        fetchReferrals();
    }, []);

    useEffect(() => {
        if (pagination.page > 1) {
            fetchReferrals(pagination.page, true);
        }
    }, [pagination.page]);

    const fetchReferrals = async (pageNum = 1, shouldAppend = false) => {
        if (!hasMore && shouldAppend) return;

        if (shouldAppend) {
            setLoading(true);
        } else {
            setInitialLoading(true);
        }

        try {
            const searchParam = searchTerm ? `search=${searchTerm}` : '';
            const categoryParam = selectedCategory ? `category=${selectedCategory}` : '';
            const pageParam = `page=${pageNum}`;
            const query = [searchParam, categoryParam, pageParam].filter(Boolean).join('&');

            const endpoint = JSON.parse(localStorage.getItem('company')) && JSON.parse(localStorage.getItem('company')).role === 'company'
                ? `/referrals/company-get-by-company?${query}`
                : `/referrals/get-by-company?${query}`;

            const response = await apiCallFetch(endpoint, 'GET');
            const newReferrals = response.data || [];

            // Clear the error if we get valid referrals
            if (newReferrals.length > 0) {
                setErr('');
            } else if (pageNum === 1) {
                // Only set the error if it's the first page and no referrals found
                setErr('No referrals found for this company.');
            }

            if (shouldAppend) {
                setReferrals(prev => [...prev, ...newReferrals]);
            } else {
                setReferrals(newReferrals);
            }

            setPagination(prev => ({
                ...prev,
                limit: response.limit,
                total: response.total
            }));

            setHasMore(response?.data?.length > 0);
            const totalPages = Math.ceil(response.total / ITEMS_PER_PAGE);
            setHasMore(pageNum < totalPages);

        } catch (error) {
            // Only set error if referrals are not already displayed
            if (!shouldAppend && referrals.length === 0) {
                setErr(error?.response?.data?.message || 'Error fetching referrals.');
            }
            console.error('Error fetching referrals:', error);
        } finally {
            if (shouldAppend) {
                setLoading(false);
            } else {
                setInitialLoading(false);
            }
        }
    };


    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Global Gives ({pagination.total})</h1>

            <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setErr('');
                    }}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, i) => (
                        <option value={category._id} key={i}>{category.name}</option>
                    ))}
                </select>
            </div>

            {initialLoading ? (
                <LoadingSpinner className="min-h-[200px] items-center" />
            ) : (
                <>
                    {err && <p>{err}</p>}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {referrals.map((referral, i) => (
                            <div
                                key={referral._id}
                                ref={i === referrals.length - 1 ? lastReferralElementRef : null}
                                className="bg-white p-4 rounded-xl shadow-lg flex flex-col relative"
                            >
                                <h2 className="font-bold text-base text-gray-800 mb-2">
                                    {referral.name}
                                    <span className="font-normal text-xs text-gray-600"> ({referral?.category?.name})</span>
                                </h2>
                                <div className="grid grid-cols-2 gap-4 text-xs text-gray-600">
                                    <div className="flex items-center space-x-1">
                                        <FaBuilding className="text-blue-500" />
                                        <span>{referral.company}</span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaCity className="text-blue-500" />
                                        <span>{referral.city}, {referral.state}</span>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4 mt-4 text-xs text-gray-600">
                                        <div className="flex items-center space-x-1">
                                            <FaEnvelope className="text-blue-500" />
                                            <span>
                                                {interestedCards[referral._id] ? referral.email : '*********@****.com'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaBuilding className="text-blue-500" />
                                        <span>{referral.department}</span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-4 mt-4 text-xs text-gray-600">
                                    <div className="flex items-center space-x-1">
                                        <FaPhone className="text-blue-500" />
                                        <span>
                                            {interestedCards[referral._id] ? referral.phone : '**** ****'}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-end justify-between mt-4 pt-4 border-t border-gray-200">
                                    <div className='flex'>
                                        <p className="text-sm text-gray-600 p-1">
                                            {referral.user?.profile?.name || 'Unknown User'}
                                        </p>
                                        <img
                                            src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'https://via.placeholder.com/40'}
                                            alt="User Avatar"
                                            className="w-10 h-10 rounded-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {loading && <LoadingSpinner />}
                </>
            )}
        </div>
    );
};

export default GlobalReferrals;
