import React, { useEffect, useState } from "react";
import { apiCallFetch } from "../api/apiHelper"; // Import the API helper

function ViewMentees() {
    const [mentees, setMentees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const mentor = JSON.parse(localStorage.getItem("user"));  // Get the entire user object
    const mentorId = mentor ? mentor._id : null;  // Assuming the mentor ID is stored as '_id' field


    useEffect(() => {
        const fetchMentees = async () => {
            try {
                const response = await apiCallFetch(`/users/get-mentee/${mentorId}`, "GET");

                // Log the full response to check its structure
                console.log(response);

                if (response.success) {  // Check for 'success' instead of 'status'
                    setMentees(response.data);  // Assuming 'data' contains mentees list
                } else {
                    setError(response.message);  // Handle error message
                }
            } catch (err) {
                console.error("Failed to fetch mentees", err);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
        fetchMentees();
    }, []); // Empty dependency array ensures this runs once after component mounts

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-gray-600">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-olive-green bg-cover bg-no-repeat p-4">
            {/* Header */}
            <div className="flex items-center justify-center my-6">
                <h1 className="text-white text-3xl font-semibold">Mentees</h1>
            </div>

            {/* Mentees List */}
            <div className="flex justify-center items-center">
                <div className="w-full max-w-4xl space-y-4">
                    {mentees.map((mentee, index) => (
                        <div
                            key={mentee._id}
                            className="flex items-center space-x-4 p-4 rounded-3xl bg-gray-50 shadow-md"
                        >
                            {/* Mentee Image */}
                            <div className="w-12 h-12">
                                <img
                                    src={mentee.image || "/user-placeholder.jpeg"}
                                    alt={mentee.name}
                                    className="w-full h-full rounded-full"
                                />
                            </div>

                            {/* Mentee Details */}
                            <div className="flex-1">
                                <p className="font-semibold text-gray-800">{mentee.name}</p>
                                <p className="text-gray-500 text-sm">{mentee.email}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ViewMentees;
