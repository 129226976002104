import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { apiCallFetch } from '../api/apiHelper'; // Adjust the path as necessary
import Toast from '../components/Toast'; // Assuming you have a Toast component for notifications

const ForgotPassword = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const [toast, setToast] = useState({ message: '', type: '', show: false });
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await apiCallFetch('/users/forgot-password', 'POST', { email }, null);
            setToast({ message: response.message, type: 'success', show: true });
            setEmail(''); // Clear email input after successful submission
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to send email');
            setToast({ message: err.response?.data?.message || 'Failed to send email', type: 'error', show: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg">
                    {/* Back Button */}
                    <button
                        type="button"
                        className="text-blue-500 text-3xl mr-2"
                        onClick={() => navigate(-1)} // Navigate back to the previous page
                    >
                        ←
                    </button>

                    <h2 className="text-center text-3xl font-bold">Forgot Password</h2>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                className="w-full p-2 mt-1 border rounded"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {error && <p className="text-red-500 text-sm">{error}</p>}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
                                disabled={loading}
                            >
                                {loading ? 'Sending...' : 'Send Reset Link'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />}
        </div>
    );
};

export default ForgotPassword;
