// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { baseURL } from "../api/apiHelper";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";
// import { Award, Book, Clock, Target } from "lucide-react";

// const StatCard = ({ icon: Icon, title, value, subtitle }) => (
//   <div className="bg-white rounded-3xl p-4 flex items-center">
//     <div className="mr-4">
//       <div className="bg-purple-100 p-3 rounded-lg">
//         <Icon className="w-6 h-6 text-purple-600" />
//       </div>
//     </div>
//     <div>
//       <p className="text-sm text-gray-500">{title}</p>
//       <p className="text-xl font-bold">{value}</p>
//       {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
//     </div>
//   </div>
// );

// const QuizCard = ({ quiz, onClick }) => (
//   <div
//     onClick={onClick}
//     className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
//   >
//     <div
//       className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz.topics[0].name.toLowerCase().includes("statistics")
//         ? "bg-purple-100"
//         : "bg-pink-100"
//         }`}
//     >
//       {quiz.topics[0].name.charAt(0).toUpperCase()}
//     </div>
//     <div className="flex-1">
//       <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
//       <div className="flex text-sm items-center gap-2 text-gray-500">
//         <span>{quiz.topics[0].name}</span>
//         <span>•</span>
//         <span>{quiz.totalQuestions} Questions</span>
//       </div>
//     </div>
//     <div className="text-purple-600">
//       <svg
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       >
//         <path d="M9 18l6-6-6-6" />
//       </svg>
//     </div>
//   </div>
// );

// const Quizzes = () => {
//   const [quizzes, setQuizzes] = useState([]);
//   const [stats, setStats] = useState(null);
//   const navigate = useNavigate();
//   const [userToken, setUserToken] = useState(
//     JSON.parse(localStorage.getItem("user"))?.token
//   );

//   useEffect(() => {
//     const fetchData = async () => {
//       const headers = {
//         Authorization: `Bearer ${userToken}`,
//       };

//       try {
//         // Fetch quizzes
//         const quizzesResponse = await fetch(
//           `${baseURL}/api/quiz/get-by-company`,
//           { headers }
//         );
//         const quizzesData = await quizzesResponse.json();
//         if (quizzesData.status) {
//           setQuizzes(quizzesData.data);
//         }

//         // Fetch stats
//         const statsResponse = await fetch(`${baseURL}/api/quiz/stats/user`, {
//           headers,
//         });
//         const statsData = await statsResponse.json();
//         if (statsData.success) {
//           setStats(statsData.data);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [userToken]);

//   return (
//     <div
//       className="min-h-screen bg-olive-green p-5"
//       style={{ minHeight: `calc(100vh - 64px)` }}
//     >
//       <div className="max-w-5xl mx-auto">
//         {/* Stats Section */}
//         {stats && (
//           <div className="mb-8">
//             <div className="mb-4 flex justify-between items-center">
//               <h2 className="text-xl font-bold mb-4 text-white">
//                 Your Progress
//               </h2>
//               <button
//                 onClick={() => navigate("/user-quiz-report")}
//                 className="text-white font-semibold hover:text-white"
//               >
//                 See All
//               </button>
//             </div>
//             <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
//               <StatCard
//                 icon={Target}
//                 title="Pass Rate"
//                 value={`${stats.passRate ? stats.passRate.toFixed(2) : 0}%`}
//               />
//               <StatCard
//                 icon={Award}
//                 title="Best Score"
//                 value={`${Math.round(stats.highestScore)}%`}
//               />
//               <StatCard
//                 icon={Book}
//                 title="Attempts"
//                 value={stats.totalAttempts}
//               />
//               <StatCard
//                 icon={Clock}
//                 title="Avg. Time/Q"
//                 value={`${stats.timeAnalysis
//                   ? stats.timeAnalysis.avgTimePerQuestion.toFixed(2)
//                   : 0
//                   }s`}
//               />
//             </div>

//             {/* Progress Chart */}
//             <div className="bg-white p-4 rounded-3xl mb-8">
//               <h3 className="text-lg font-semibold  mb-4">
//                 Progress Over Time
//               </h3>
//               <div className="h-48">
//                 <ResponsiveContainer width="100%" height="100%">
//                   <BarChart data={stats.progressOverTime}>
//                     <XAxis dataKey="_id" />
//                     <YAxis />
//                     <Tooltip />
//                     <Bar dataKey="avgScore" fill="#8b5cf6" />
//                   </BarChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//           </div>
//         )}

//         {/* Recent Quizzes Section */}
//         <div className="mb-4 flex justify-between items-center">
//           <h2 className="text-xl font-bold text-white">Recent Quizzes</h2>
//           <button
//             onClick={() => navigate("/all-quizzes")}
//             className="text-white font-semibold hover:text-white"
//           >
//             See All
//           </button>
//         </div>

//         <div className="space-y-4">
//           {quizzes.slice(0, 5).map((quiz) => (
//             <QuizCard
//               key={quiz._id}
//               quiz={quiz}
//               onClick={() => navigate(`/quiz/${quiz._id}`)}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Quizzes;


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCallFetch } from "../api/apiHelper";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Award, Book, Clock, Target } from "lucide-react";

const StatCard = ({ icon: Icon, title, value, subtitle }) => (
  <div className="bg-white rounded-3xl p-4 flex items-center">
    <div className="mr-4">
      <div className="bg-purple-100 p-3 rounded-lg">
        <Icon className="w-6 h-6 text-purple-600" />
      </div>
    </div>
    <div>
      <p className="text-sm text-gray-500">{title}</p>
      <p className="text-xl font-bold">{value}</p>
      {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
    </div>
  </div>
);

const QuizCard = ({ quiz, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
  >
    <div
      className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz.topics[0].name.toLowerCase().includes("statistics")
        ? "bg-purple-100"
        : "bg-pink-100"
        }`}
    >
      {quiz.topics[0].name.charAt(0).toUpperCase()}
    </div>
    <div className="flex-1">
      <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
      <div className="flex text-sm items-center gap-2 text-gray-500">
        <span>{quiz.topics[0].name}</span>
        <span>•</span>
        <span>{quiz.totalQuestions} Questions</span>
      </div>
    </div>
    <div className="text-purple-600">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  </div>
);

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch quizzes
        const quizzesData = await apiCallFetch("/quiz/get-by-company", "GET");
        if (quizzesData.status) {
          setQuizzes(quizzesData.data);
        }

        // Fetch stats
        const statsData = await apiCallFetch("/quiz/stats/user", "GET");
        if (statsData.success) {
          setStats(statsData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className="min-h-screen bg-olive-green p-5"
      style={{ minHeight: `calc(100vh - 64px)` }}
    >
      <div className="max-w-5xl mx-auto">
        {/* Stats Section */}
        {stats && (
          <div className="mb-8">
            <div className="mb-4 flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4 text-white">
                Your Progress
              </h2>
              <button
                onClick={() => navigate("/user-quiz-report")}
                className="text-white font-semibold hover:text-white"
              >
                See All
              </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              <StatCard
                icon={Target}
                title="Pass Rate"
                value={`${stats.passRate ? stats.passRate.toFixed(2) : 0}%`}
              />
              <StatCard
                icon={Award}
                title="Best Score"
                value={`${Math.round(stats.highestScore)}%`}
              />
              <StatCard
                icon={Book}
                title="Attempts"
                value={stats.totalAttempts}
              />
              <StatCard
                icon={Clock}
                title="Avg. Time/Q"
                value={`${stats.timeAnalysis
                  ? stats.timeAnalysis.avgTimePerQuestion.toFixed(2)
                  : 0
                  }s`}
              />
            </div>

            {/* Progress Chart */}
            <div className="bg-white p-4 rounded-3xl mb-8">
              <h3 className="text-lg font-semibold mb-4">
                Progress Over Time
              </h3>
              <div className="h-48">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={stats.progressOverTime}>
                    <XAxis dataKey="_id" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="avgScore" fill="#8b5cf6" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )}

        {/* Recent Quizzes Section */}
        <div className="mb-4 flex justify-between items-center">
          <h2 className="text-xl font-bold text-white">Recent Quizzes</h2>
          <button
            onClick={() => navigate("/all-quizzes")}
            className="text-white font-semibold hover:text-white"
          >
            See All
          </button>
        </div>

        <div className="space-y-4">
          {quizzes.slice(0, 5).map((quiz) => (
            <QuizCard
              key={quiz._id}
              quiz={quiz}
              onClick={() => navigate(`/quiz/${quiz._id}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Quizzes;
