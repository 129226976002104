import React, { useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const AddBulkReferral = () => {
    const [file, setFile] = useState(null);
    const [formErr, setFormError] = useState('');
    const navigate = useNavigate();

    const handleFileSubmit = () => {
        // Add your file upload logic here
        console.log(file);
    };

    return (
        <div className="p-6">
            {/* Back Button */}
            <button
                type="button"
                className="text-blue-500 text-3xl mr-2"
                onClick={() => navigate(-1)} // Navigate back to the previous page
            >
                ←
            </button>
            <h2 className="text-lg font-bold inline">Add Bulk Referral</h2> {/* Added inline to keep them on the same line */}
            <div className="mb-4">
                <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700 mb-2">
                    Select Excel File
                </label>
                <div className="flex items-center justify-center w-full">
                    <label
                        htmlFor="file-upload"
                        className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <FaFileExcel className="w-10 h-10 mb-3 text-gray-400" />
                            <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs text-gray-500">Excel file (XLSX)</p>
                        </div>
                        <input
                            id="file-upload"
                            type="file"
                            className="hidden"
                            onChange={(e) => {
                                setFile(e.target.files[0]);
                                setFormError('');
                            }}
                            accept=".xlsx"
                        />
                    </label>
                </div>
                {file && <p className="text-sm text-gray-600 mb-4">Selected file: {file.name}</p>}
                {formErr && <p className='text-sm text-red-500'>{formErr}</p>}
                <button
                    disabled
                    type="button"
                    className="bg-blue-500 text-white py-2 px-4 rounded w-full"
                    onClick={handleFileSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default AddBulkReferral;
