import React, { useState, useEffect } from 'react';
import { FaBuilding, FaEdit, FaTrash, FaEnvelope, FaPhone, FaPlus } from 'react-icons/fa'; // Import FaPlus for the button
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate } from 'react-router-dom';

const CompanyMaster = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                setLoading(true);
                const response = await apiCallFetch('/company/list', 'GET');
                if (response.status) {
                    setCompanies(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('Error fetching companies');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const handleUpdate = (company) => {
        navigate('/add-company', { state: { company } });
    };

    const handleDeleteCompany = async (companyId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this company?');
        if (!isConfirmed) return;

        try {
            // Use PUT method and send the company ID in the request body
            const response = await apiCallFetch('/company/delete', 'PUT', { id: companyId });

            if (response.status) {
                setCompanies((prevCompanies) => prevCompanies.filter((company) => company._id !== companyId));
            } else {
                alert(response.message);
            }
        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting company');
            console.error('Error deleting company:', error);
        }
    };


    const LoadingSpinner = () => (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Company Master</h1>

                {/* Add Company Button aligned to the right */}
                <button
                    onClick={() => navigate('/add-company')} // Navigate to the desired route 
                    className="bg-blue-500 text-white p-2 rounded flex items-center"
                >
                    <FaPlus className="mr-2" /> Add Company
                </button>
            </div>

            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {companies.map((company) => (
                        <div key={company._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col space-y-4 relative">
                            <div className="absolute top-2 right-2 flex space-x-2">
                                <button onClick={() => handleUpdate(company)} className="text-gray-600 hover:text-blue-500">
                                    <FaEdit />
                                </button>
                                <button onClick={() => handleDeleteCompany(company._id)} className="text-gray-600 hover:text-red-500">
                                    <FaTrash />
                                </button>
                            </div>

                            <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
                                <FaBuilding className="text-blue-500" />
                                <span>{company.name}</span>
                            </h2>

                            <div className="text-sm text-gray-600 space-y-1">
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-blue-500" />
                                    <span>{company.email || 'N/A'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaPhone className="text-blue-500" />
                                    <span>{company.phone || 'N/A'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <span>Contact Person:</span>
                                    <span>{company.contactPerson || 'N/A'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <span>Location:</span>
                                    <span>{company.city}, {company.state}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyMaster;
