import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';

const AddCategory = () => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const isEditMode = !!location.state?.category; // Check if we're in edit mode
    const category = location.state?.category;

    useEffect(() => {
        if (isEditMode) {
            setName(category.name); // Set the name for edit mode
        }
    }, [isEditMode, category]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const endpoint = isEditMode ? '/categories/update' : '/categories/create';
            const method = isEditMode ? 'PUT' : 'POST';

            const requestBody = isEditMode ? { id: category._id, name } : { name };

            const response = await apiCallFetch(endpoint, method, requestBody);

            if (response.status) {
                const message = isEditMode ? 'Category updated successfully!' : 'Category created successfully!';
                setSuccessMessage(message);
                setTimeout(() => {
                    setSuccessMessage('');
                    navigate('/give-category');
                }, 2000);
            } else {
                setError(response.message); // Update error handling to show the exact error message
            }
        } catch (err) {
            setError('Error processing request: ' + (err?.response?.data?.message || 'An unknown error occurred'));
            console.error('Error details:', err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="p-4">
            <button
                type="button"
                className="text-blue-500 text-3xl mr-2"
                onClick={() => navigate(-1)}
            >
                ←
            </button>
            <span className="text-2xl font-bold">{isEditMode ? 'Update Category' : 'Add Category'}</span>

            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-xl shadow-lg mt-4">
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Category Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="border p-2 w-full"
                    />
                </div>
                <button type="submit" disabled={loading} className="bg-blue-500 text-white p-2 rounded w-full">
                    {isEditMode ? 'Update Category' : 'Add Category'}
                </button>
            </form>
        </div>
    );
};

export default AddCategory;
