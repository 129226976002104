import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper'; // Adjust path as needed
import Toast from '../components/Toast'; // Assuming you have a Toast component for notifications
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function ResetPassword() {
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate(); // Initialize navigate function
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [toast, setToast] = useState({ message: '', type: '', show: false });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const data = await apiCallFetch(`/users/reset-password/${token}`, 'POST', { password });
            setToast({ message: data.message, type: 'success', show: true });

            // Delay navigation to login page for 2 seconds
            setTimeout(() => {
                navigate('/login'); // Redirect to login
            }, 2000);
        } catch (error) {
            setError('Failed to reset password. Please try again.');
            setToast({ message: 'Failed to reset password. Please try again.', type: 'error', show: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg">
                    {/* Back Button */}
                    <button
                        type="button"
                        className="text-blue-500 text-3xl mr-2"
                        onClick={() => navigate(-1)} // Navigate back to the previous page
                    >
                        ←
                    </button>

                    <h2 className="text-center text-3xl font-bold">Reset Password</h2>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">New Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'} // Toggle password visibility
                                    className="w-full p-2 mt-1 border rounded"
                                    placeholder="Enter new password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-600" // Change color on hover
                                    onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                                    aria-label="Toggle password visibility"
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'} // Toggle password visibility
                                    className="w-full p-2 mt-1 border rounded"
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-600" // Change color on hover
                                    onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                                    aria-label="Toggle password visibility"
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                            {error && <p className="text-red-500 text-sm">{error}</p>}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
                                disabled={loading}
                            >
                                {loading ? 'Resetting...' : 'Reset Password'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {toast.show && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />}
        </div>
    );
}

export default ResetPassword;
