import React, { useState, useEffect } from "react";
import { baseURL } from "../api/apiHelper";

const QuizReportUser = () => {
    const [quizAttempts, setQuizAttempts] = useState([]);
    const [userToken, setUserToken] = useState(
        JSON.parse(localStorage.getItem("user"))?.token
    );

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(baseURL + "/api/quiz/attempt/user", requestOptions)
            .then((response) => response.json())
            .then((result) => setQuizAttempts(result.data))
            .catch((error) => console.error(error));
    }, []);

    return (
        <div className="min-h-screen bg-olive-green p-5"
            style={{ minHeight: `calc(100vh - 64px)` }}>
            <h1 className="text-xl text-white font-bold mb-4 text-center">
                Quiz Attempts
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {quizAttempts.map((attempt, index) => (
                    <QuizAttemptCard key={index} attempt={attempt} />
                ))}
            </div>
        </div>
    );
};

const QuizAttemptCard = ({ attempt }) => {
    const [showQuestions, setShowQuestions] = useState(false);

    return (
        <div className="bg-white rounded-3xl shadow-md overflow-hidden mb-4">
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{attempt.quizName}</div>
                {attempt?.attempts?.map((a, index) => (
                    <div key={index} className="mb-4 border-b pb-4">
                        <p className="text-gray-700 text-base">
                            Attempt on {new Date(a.startTime).toLocaleString()}
                        </p>
                        <div className="px-6 py-4 border-t">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <p className="font-medium">Score:</p>
                                    <p>
                                        {a.score} / {a.totalPossibleScore}
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium">Percentage:</p>
                                    <p>{a.percentageScore.toFixed(2)}%</p>
                                </div>
                                <div>
                                    <p className="font-medium">Status:</p>
                                    <p
                                        className={`font-medium ${a.passed ? "text-green-500" : "text-red-500"
                                            }`}
                                    >
                                        {a.passed ? "Passed" : "Failed"}
                                    </p>
                                </div>
                                <div>
                                    <p className="font-medium">Time Spent:</p>
                                    <p>{a.timeSpent ? a.timeSpent + " seconds" : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setShowQuestions(!showQuestions)}
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            {showQuestions ? "Hide Questions" : "Show Questions"}
                        </button>
                        {showQuestions && (
                            <div className="mt-4">
                                {a.answers.map((q, i) => (
                                    <div key={i} className="mb-4 p-4 bg-gray-100 rounded shadow">
                                        <p className="font-medium text-lg">{q.question}</p>
                                        <div className="ml-4 mt-2">
                                            {q.options.map((option) => (
                                                <p
                                                    key={option._id}
                                                    className={`${option.isCorrect ? "text-green-600" : ""
                                                        } ${option._id === q.selectedOption._id
                                                            ? "font-semibold underline"
                                                            : ""
                                                        }`}
                                                >
                                                    - {option.text}
                                                </p>
                                            ))}
                                        </div>
                                        <p
                                            className={`mt-2 ${q.isCorrect ? "text-green-600" : "text-red-600"
                                                }`}
                                        >
                                            Your answer: {q.selectedOption.text} (
                                            {q.isCorrect ? "Correct" : "Incorrect"})
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Time spent: {q.timeSpent} seconds
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuizReportUser;
