import React, { useEffect, useState } from "react";
import { apiCallFetch } from "../api/apiHelper";

function TopContributors() {
    const [topContributors, setTopContributors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTopContributors = async () => {
            try {
                const response = await apiCallFetch("/users/get-dashboard", "GET");
                if (response.status) {
                    const { topContributors } = response.data;
                    setTopContributors(topContributors);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                console.error("Failed to fetch top contributors", err);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchTopContributors();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-gray-600">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-olive-green bg-cover bg-no-repeat p-4">
            {/* Header */}
            <div className="flex items-center justify-center my-6">
                <h1 className="text-white text-3xl font-semibold">Top Contributors</h1>
            </div>

            {/* Contributors List */}
            <div className="flex justify-center items-center">
                <div className="w-full max-w-4xl space-y-4">
                    {topContributors.map((contributor, index) => (
                        <div
                            key={contributor.id}
                            className="flex items-center space-x-4 p-4 rounded-3xl bg-gray-50 shadow-md"
                        >
                            {/* Rank Number */}
                            <div className="w-8 h-8 flex items-center justify-center text-gray-400">
                                {index + 1}
                            </div>

                            {/* Contributor Image */}
                            <div className="w-12 h-12">
                                <img
                                    src={contributor.image || "/user-placeholder.jpeg"}
                                    alt={contributor.name}
                                    className="w-full h-full rounded-full"
                                />
                            </div>

                            {/* Contributor Details */}
                            <div className="flex-1">
                                <p className="font-semibold text-gray-800">{contributor.name}</p>
                                <p className="text-gray-500 text-sm">{contributor.referralCount} Gives</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopContributors;
