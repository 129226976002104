// Toast.js
import React, { useEffect } from 'react';

const Toast = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
        return () => clearTimeout(timer);
    }, [onClose]);

    const toastStyles = type === 'success'
        ? 'bg-green-100 text-green-800 border-green-400'
        : 'bg-red-100 text-red-800 border-red-400';

    return (
        <div className={`fixed top-4 right-4 z-50 p-4 border rounded-lg shadow-lg ${toastStyles}`}>
            <div className="flex items-center">
                <div className="mr-3">
                    {type === 'success' ? (
                        <svg className="w-6 h-6 fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-17l5.707 5.707-1.414 1.414L11 8.414l-2.293 2.293-1.414-1.414L11 6z" />
                        </svg>
                    ) : (
                        <svg className="w-6 h-6 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
                        </svg>
                    )}
                </div>
                <div>
                    <p>{message}</p>
                </div>
                <button
                    className="ml-4 text-gray-500"
                    onClick={onClose}
                >
                    ✖
                </button>
            </div>
        </div>
    );
};

export default Toast;
