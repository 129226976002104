import React from 'react';

const AdminDashboard = () => {
    return (
        <div style={{ padding: '1rem' }}>
            <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Admin Dashboard</h1>
        </div>
    );
};

export default AdminDashboard;