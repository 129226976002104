// import React, { useState, useEffect } from 'react';
// import { FaEnvelope, FaPhone, FaUser, FaPlus, FaEdit, FaTrash, FaTrophy } from 'react-icons/fa';
// import { apiCallFetch } from '../api/apiHelper';
// import { useNavigate } from 'react-router-dom';

// const CompanyUsers = () => {
//     const [users, setUsers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchCompanyUsers = async () => {
//             try {
//                 setLoading(true);
//                 const response = await apiCallFetch('/company/get-users', 'GET');
//                 if (response.status) {
//                     setUsers(response.data);
//                 } else {
//                     setError(response.message);
//                 }
//             } catch (err) {
//                 setError('Error fetching company users');
//                 console.error(err);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchCompanyUsers();
//     }, []);

//     const handleUpdate = (user) => {
//         navigate('/add-company-user', { state: { user } });
//     };

//     const handleDeleteUser = async (userId) => {
//         const isConfirmed = window.confirm('Are you sure you want to delete this user?');
//         if (!isConfirmed) return;

//         try {
//             const response = await apiCallFetch(`/company/delete-user/${userId}`, 'DELETE');
//             if (response.status) {
//                 setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
//                 // alert('User deleted successfully.');
//             }
//         } catch (error) {
//             alert(error?.response?.data?.message || 'Error deleting user');
//             console.error('Error deleting user:', error);
//         }
//     };

//     const handleAddAttempts = (userId) => {
//         // Navigate to the quiz report page for the specific user
//         navigate(`/company-quiz-report/${userId}`);
//     };

//     const LoadingSpinner = () => (
//         <div className="flex justify-center items-center h-full">
//             <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
//         </div>
//     );

//     return (
//         <div className="p-4">
//             <div className="flex justify-between items-center mb-4">
//                 <h1 className="text-2xl font-bold">Company Users</h1>
//                 <button
//                     onClick={() => navigate('/add-company-user')}
//                     className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
//                 >
//                     <FaPlus className="mr-2" /> Add User
//                 </button>
//             </div>
//             {loading ? (
//                 <LoadingSpinner />
//             ) : error ? (
//                 <p className="text-red-500">{error}</p>
//             ) : (
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                     {users.map((user) => (
//                         <div key={user._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col items-start space-y-4 relative" style={{ minHeight: '100px' }}>
//                             <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
//                                 <FaUser className="text-blue-500" />
//                                 <span>{user.profile.name}({user.role})</span>
//                             </h2>
//                             <div className="text-sm text-gray-600">
//                                 <div className="flex items-center space-x-2">
//                                     <FaEnvelope className="text-blue-500" />
//                                     <span>{user.email}</span>
//                                 </div>
//                                 <div className="flex items-center space-x-2">
//                                     <FaPhone className="text-blue-500" />
//                                     <span>{user.profile.phone || 'N/A'}</span>
//                                 </div>
//                             </div>
//                             <div className="absolute top-2 right-2 flex space-x-2">
//                                 <button onClick={() => handleUpdate(user)} className="text-gray-600 hover:text-blue-500">
//                                     <FaEdit />
//                                 </button>
//                                 <button onClick={() => handleDeleteUser(user._id)} className="text-gray-600 hover:text-red-500">
//                                     <FaTrash />
//                                 </button>
//                             </div>
//                             {/* Add Attempt button */}
//                             <button
//                                 onClick={() => handleAddAttempts(user._id)}
//                                 className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-green-700 mt-4"
//                             >
//                                 <FaTrophy className="mr-2" /> View Attempts
//                             </button>
//                         </div>
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// };

// export default CompanyUsers;



import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaPhone, FaUser, FaPlus, FaEdit, FaTrash, FaTrophy, FaUserPlus } from 'react-icons/fa';
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate } from 'react-router-dom';

const CompanyUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanyUsers = async () => {
            try {
                setLoading(true);
                const response = await apiCallFetch('/company/get-users', 'GET');
                if (response.status) {
                    setUsers(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('Error fetching company users');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyUsers();
    }, []);

    const handleUpdate = (user) => {
        navigate('/add-company-user', { state: { user } });
    };

    const handleDeleteUser = async (userId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this user?');
        if (!isConfirmed) return;

        try {
            const response = await apiCallFetch(`/company/delete-user/${userId}`, 'DELETE');
            if (response.status) {
                setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
            }
        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting user');
            console.error('Error deleting user:', error);
        }
    };

    const handleAddAttempts = (userId) => {
        navigate(`/company-quiz-report/${userId}`);
    };

    const handleAddMentor = (userId) => {
        navigate(`/add-mentor/${userId}`);
    };


    const LoadingSpinner = () => (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Company Users</h1>
                <button
                    onClick={() => navigate('/add-company-user')}
                    className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                    <FaPlus className="mr-2" /> Add User
                </button>
            </div>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {users.map((user) => (
                        <div key={user._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col items-start space-y-4 relative" style={{ minHeight: '100px' }}>
                            <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
                                <FaUser className="text-blue-500" />
                                <span>{user.profile.name}({user.role})</span>
                            </h2>
                            <div className="text-sm text-gray-600">
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-blue-500" />
                                    <span>{user.email}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaPhone className="text-blue-500" />
                                    <span>{user.profile.phone || 'N/A'}</span>
                                </div>
                            </div>
                            <div className="absolute top-2 right-2 flex space-x-2">
                                <button onClick={() => handleUpdate(user)} className="text-gray-600 hover:text-blue-500">
                                    <FaEdit />
                                </button>
                                <button onClick={() => handleDeleteUser(user._id)} className="text-gray-600 hover:text-red-500">
                                    <FaTrash />
                                </button>
                            </div>
                            {/* View Attempts and Add Mentor buttons */}
                            <div className="flex space-x-2 mt-4">
                                <button
                                    onClick={() => handleAddAttempts(user._id)}
                                    className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                >
                                    <FaTrophy className="mr-2" /> View Attempts
                                </button>
                                {user.role !== 'mentor' && (
                                    <button
                                        onClick={() => handleAddMentor(user._id)}
                                        className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                    >
                                        <FaUserPlus className="mr-2" /> Add Mentor
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyUsers;
