import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper'; // Adjust this path if needed
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // For toggle icon

function QuizReport() {
    const [quizAttempts, setQuizAttempts] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch quiz attempts from API
    const fetchQuizAttempts = async (page = 1) => {
        setLoading(true);
        try {
            const response = await apiCallFetch(`/quiz/all-attempts?page=${page}&limit=10`, 'GET');
            setQuizAttempts(response.data.attempts);
            setPagination(response.data.pagination);
        } catch (err) {
            setError('Failed to load quiz attempts');
            console.error('API Error:', err);
        } finally {
            setLoading(false);
        }
    };

    // Toggle the details of each quiz attempt
    const toggleDetails = (index) => {
        setQuizAttempts((prevState) =>
            prevState.map((attempt, i) =>
                i === index ? { ...attempt, showDetails: !attempt.showDetails } : attempt
            )
        );
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchQuizAttempts();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-50">
                <div className="loader animate-spin rounded-full border-t-4 border-blue-600 h-12 w-12"></div>
                <p className="text-lg text-gray-500 ml-4">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-50">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg">
            <h1 className="text-4xl font-bold text-gray-800 mb-8">Quiz Attempts Report</h1>

            {quizAttempts.length > 0 ? (
                <table className="min-w-full table-auto border-collapse shadow-lg rounded-lg overflow-hidden">
                    <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
                        <tr>
                            <th className="py-4 px-6 text-left">User Email</th>
                            <th className="py-4 px-6 text-left">Quiz Name</th>
                            <th className="py-4 px-6 text-left">Score</th>
                            <th className="py-4 px-6 text-left">Percentage</th>
                            <th className="py-4 px-6 text-left">Status</th>
                            <th className="py-4 px-6 text-left">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quizAttempts.map((attempt, index) => (
                            <React.Fragment key={attempt._id}>
                                <tr className="border-b hover:bg-gray-50 transition-all">
                                    <td className="py-4 px-6 text-gray-700">{attempt.user.email}</td>
                                    <td className="py-4 px-6 text-gray-700">{attempt.quiz.name}</td>
                                    <td className="py-4 px-6 text-gray-700">{attempt.score} / {attempt.totalPossibleScore}</td>
                                    <td className="py-4 px-6 text-gray-700">
                                        {((attempt.score / attempt.totalPossibleScore) * 100).toFixed(2)}%
                                    </td>
                                    <td className="py-4 px-6 text-gray-700">{attempt.status}</td>
                                    <td className="py-4 px-6 text-gray-700">
                                        <button
                                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
                                            onClick={() => toggleDetails(index)}
                                        >
                                            {attempt.showDetails ? (
                                                <FaChevronUp className="mr-2 inline" />
                                            ) : (
                                                <FaChevronDown className="mr-2 inline" />
                                            )}
                                            {attempt.showDetails ? 'Hide Details' : 'Show Details'}
                                        </button>
                                    </td>
                                </tr>

                                {/* Details Section */}
                                {attempt.showDetails && (
                                    <tr className="bg-gray-50 transition-all">
                                        <td colSpan="6" className="py-6 px-6">
                                            <div className="space-y-6">
                                                <h3 className="text-2xl font-semibold text-gray-800">Quiz Answers</h3>
                                                <div className="space-y-4">
                                                    {attempt.answers.map((answer, idx) => (
                                                        <div key={idx} className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm transition-all">
                                                            <p className="text-lg font-medium text-gray-700">
                                                                <strong>{idx + 1}: </strong>{answer.question}
                                                            </p>
                                                            <div className="mt-2 space-y-2">
                                                                <p className="font-semibold">Answer Options:</p>
                                                                {answer.options.map((option, optionIndex) => (
                                                                    <div
                                                                        key={optionIndex}
                                                                        className={`py-2 px-4 rounded-md flex items-center space-x-2 ${option.isCorrect
                                                                            ? 'bg-green-100 text-green-700'
                                                                            : option.text === answer.selectedOption.text
                                                                                ? 'bg-red-100 text-red-700'
                                                                                : 'bg-gray-100 text-gray-700'
                                                                            }`}
                                                                    >
                                                                        <p>{option.text}</p>
                                                                        {option.isCorrect && (
                                                                            <span className="text-green-500">(Correct)</span>
                                                                        )}
                                                                        {option.text === answer.selectedOption.text && !option.isCorrect && (
                                                                            <span className="text-red-500">(Selected)</span>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <p className="mt-2"><strong>Time spent:</strong> {answer.timeSpent} seconds</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="space-y-2 mt-4">
                                                    <p><strong>Total Score:</strong> {attempt.score} / {attempt.totalPossibleScore}</p>
                                                    <p><strong>Percentage:</strong> {((attempt.score / attempt.totalPossibleScore) * 100).toFixed(2)}%</p>
                                                    <p><strong>Status:</strong> {attempt.status}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">No quiz attempts available.</p>
            )}

            {/* Pagination Section */}
            <div className="flex justify-between items-center mt-6">
                <div className="text-gray-600">
                    <p className="text-sm">Page {pagination.page} of {pagination.pages}</p>
                </div>
                <div className="flex space-x-3">
                    <button
                        disabled={pagination.page <= 1}
                        onClick={() => fetchQuizAttempts(pagination.page - 1)}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
                    >
                        Previous
                    </button>
                    <button
                        disabled={pagination.page >= pagination.pages}
                        onClick={() => fetchQuizAttempts(pagination.page + 1)}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default QuizReport;




//// show one user wise one user and all attempts

// import React, { useEffect, useState } from 'react';
// import { apiCallFetch } from '../api/apiHelper';
// import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

// function QuizReport() {
//     const [quizAttempts, setQuizAttempts] = useState([]);
//     const [groupedAttempts, setGroupedAttempts] = useState({});
//     const [pagination, setPagination] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Fetch quiz attempts from API
//     const fetchQuizAttempts = async (page = 1) => {
//         setLoading(true);
//         try {
//             const response = await apiCallFetch(`/quiz/all-attempts?page=${page}&limit=10`, 'GET');
//             setQuizAttempts(response.data.attempts);
//             setPagination(response.data.pagination);
//             groupAttemptsByUser(response.data.attempts); // Group by user after fetching
//         } catch (err) {
//             setError('Failed to load quiz attempts');
//             console.error('API Error:', err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Group quiz attempts by user ID
//     const groupAttemptsByUser = (attempts) => {
//         const grouped = attempts.reduce((acc, attempt) => {
//             const userId = attempt.user._id;
//             if (!acc[userId]) {
//                 acc[userId] = { user: attempt.user, attempts: [], showDetails: false };
//             }
//             acc[userId].attempts.push(attempt);
//             return acc;
//         }, {});
//         setGroupedAttempts(grouped);
//     };

//     // Toggle the details of each user's attempts
//     const toggleUserDetails = (userId) => {
//         setGroupedAttempts((prev) => ({
//             ...prev,
//             [userId]: {
//                 ...prev[userId],
//                 showDetails: !prev[userId].showDetails,
//             },
//         }));
//     };

//     useEffect(() => {
//         fetchQuizAttempts();
//     }, []);

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <div className="loader animate-spin rounded-full border-t-4 border-blue-600 h-12 w-12"></div>
//                 <p className="text-lg text-gray-500 ml-4">Loading...</p>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
//             </div>
//         );
//     }

//     return (
//         <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg">
//             <h1 className="text-4xl font-bold text-gray-800 mb-8">Quiz Attempts Report</h1>

//             {Object.keys(groupedAttempts).length > 0 ? (
//                 <table className="min-w-full table-auto border-collapse shadow-lg rounded-lg overflow-hidden">
//                     <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
//                         <tr>
//                             <th className="py-4 px-6 text-left">User Email</th>
//                             <th className="py-4 px-6 text-left">Quiz Attempts</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {Object.values(groupedAttempts).map(({ user, attempts, showDetails }) => (
//                             <React.Fragment key={user._id}>
//                                 <tr className="border-b hover:bg-gray-50 transition-all">
//                                     <td className="py-4 px-6 text-gray-700">{user.email}</td>
//                                     <td className="py-4 px-6">
//                                         <button
//                                             className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
//                                             onClick={() => toggleUserDetails(user._id)}
//                                         >
//                                             {showDetails ? (
//                                                 <FaChevronUp className="mr-2 inline" />
//                                             ) : (
//                                                 <FaChevronDown className="mr-2 inline" />
//                                             )}
//                                             {showDetails ? 'Hide Details' : 'Show Details'}
//                                         </button>
//                                     </td>
//                                 </tr>

//                                 {/* Display attempts if showDetails is true */}
//                                 {showDetails && attempts.map((attempt, attemptIndex) => (
//                                     <tr key={attempt._id} className="bg-gray-50">
//                                         <td colSpan="2" className="py-6 px-6">
//                                             <div className="mb-4">
//                                                 <h3 className="text-2xl font-semibold text-gray-800 mb-2">Quiz: {attempt.quiz.name}</h3>
//                                                 <p><strong>Score:</strong> {attempt.score} / {attempt.totalPossibleScore}</p>
//                                                 <p><strong>Percentage:</strong> {((attempt.score / attempt.totalPossibleScore) * 100).toFixed(2)}%</p>
//                                                 <p><strong>Status:</strong> {attempt.status}</p>
//                                             </div>
//                                             <div className="space-y-4">
//                                                 <h4 className="text-xl font-semibold text-gray-700">Questions & Answers</h4>
//                                                 {attempt.answers.map((answer, idx) => (
//                                                     <div key={idx} className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
//                                                         <p className="text-lg font-medium text-gray-700">
//                                                             <strong>{idx + 1}:</strong> {answer.question}
//                                                         </p>
//                                                         <div className="mt-2 space-y-2">
//                                                             <p className="font-semibold">Answer Options:</p>
//                                                             {answer.options.map((option, optionIndex) => (
//                                                                 <div
//                                                                     key={optionIndex}
//                                                                     className={`py-2 px-4 rounded-md flex items-center space-x-2 ${option.isCorrect
//                                                                         ? 'bg-green-100 text-green-700'
//                                                                         : option.text === answer.selectedOption?.text
//                                                                             ? 'bg-red-100 text-red-700'
//                                                                             : 'bg-gray-100 text-gray-700'
//                                                                         }`}
//                                                                 >
//                                                                     <p>{option.text}</p>
//                                                                     {option.isCorrect && (
//                                                                         <span className="text-green-500">(Correct)</span>
//                                                                     )}
//                                                                     {option.text === answer.selectedOption?.text && !option.isCorrect && (
//                                                                         <span className="text-red-500">(Selected)</span>
//                                                                     )}
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                         <p className="mt-2"><strong>Time spent:</strong> {answer.timeSpent} seconds</p>
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             ) : (
//                 <p className="text-center text-gray-500">No quiz attempts available.</p>
//             )}

//             {/* Pagination Section */}
//             <div className="flex justify-between items-center mt-6">
//                 <div className="text-gray-600">
//                     <p className="text-sm">Page {pagination.page} of {pagination.pages}</p>
//                 </div>
//                 <div className="flex space-x-3">
//                     <button
//                         disabled={pagination.page <= 1}
//                         onClick={() => fetchQuizAttempts(pagination.page - 1)}
//                         className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
//                     >
//                         Previous
//                     </button>
//                     <button
//                         disabled={pagination.page >= pagination.pages}
//                         onClick={() => fetchQuizAttempts(pagination.page + 1)}
//                         className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
//                     >
//                         Next
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default QuizReport;
