
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaPlus, FaTrash, FaCity, FaBuilding, FaPhone, FaEnvelope, FaEdit, FaFileExcel } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiCallFetch } from '../api/apiHelper';
import { City, State } from 'country-state-city';
import { useLocation, useNavigate } from 'react-router-dom';

const MyReferrals = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const [referrals, setReferrals] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allCity, setAllCity] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [err, setErr] = useState('');
    const [bulkModalOpen, setBulkModalOpen] = useState(false);
    const [formErr, setFormError] = useState('');
    const [totalReferal, setTotalReferal] = useState(0);
    const [file, setFile] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const ITEMS_PER_PAGE = 10;
    const observer = useRef();

    const lastReferralElementRef = useCallback(node => {
        if (loadingMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loadingMore, hasMore]);


    const formik = useFormik({
        initialValues: {
            name: '',
            category: '',
            department: '',
            company: '',
            city: '',
            state: '',
            phone: '',
            email: '',
            referralId: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            category: Yup.string().required('Category is required'),
            department: Yup.string().required('Department is required'),
            company: Yup.string().required('Company is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            // phone: Yup.string().required('Phone is required'),
            // email: Yup.string().email('Invalid email format').required('Email is required'),
        }),
    });

    const LoadingSpinner = ({ className }) => (
        <div className={`flex justify-center ${className}`}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    const fetchReferrals = async (pageNum = 1, shouldAppend = false) => {
        if (!hasMore && shouldAppend) return;

        if (shouldAppend) {
            setLoadingMore(true);
        } else {
            setInitialLoading(true);
        }
        try {
            const searchParam = searchTerm ? `search=${searchTerm}` : '';
            const categoryParam = selectedCategory ? `category=${selectedCategory}` : '';
            const pageParam = `page=${pageNum}`;
            const query = [searchParam, categoryParam, pageParam].filter(Boolean).join('&');

            const response = await apiCallFetch(`/referrals/get-by-user?${query}`, 'GET');

            const newReferrals = response.data || [];

            if (shouldAppend) {
                setReferrals(prev => [...prev, ...newReferrals]);
            } else {
                setReferrals(newReferrals);
            }

            setTotalReferal(response.total);

            // Check if we've reached the end of the data
            const totalPages = Math.ceil(response.total / ITEMS_PER_PAGE);
            setHasMore(pageNum < totalPages);

        } catch (error) {
            setErr(error?.response?.data?.message);
            if (!shouldAppend) setReferrals([]);
            console.error('Error fetching referrals:', error);
        } finally {
            if (shouldAppend) {
                setLoadingMore(false);
            } else {
                setInitialLoading(false);
            }
        }
    };

    useEffect(() => {
        setPage(1);
        setReferrals([]);
        setHasMore(true); // Reset hasMore when search terms change
        fetchReferrals(1, false);
    }, [searchTerm, selectedCategory]);

    useEffect(() => {
        if (page > 1) {
            fetchReferrals(page, true);
        }
    }, [page]);

    const fetchCategories = async () => {
        try {
            const response = await apiCallFetch('/categories/get', 'GET');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        fetchReferrals();
        fetchCategories();
        getStates();
    }, []);

    useEffect(() => {
        fetchReferrals();
    }, [searchTerm, selectedCategory]);

    const handleDeleteReferral = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this referral?');
        if (!isConfirmed) return;

        try {
            const response = await apiCallFetch(`/referrals/delete`, 'DELETE', { referralId: id });
            if (response.status) {
                fetchReferrals();
            }
        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting referral')
            console.error('Error deleting referral:', error);
        }
    };

    const handleSubmit = async () => {
        const errors = await formik.validateForm();
        formik.setTouched({
            name: true,
            category: true,
            department: true,
            company: true,
            city: true,
            state: true,
            phone: true,
            email: true,
        });

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            try {
                await isUpdate ? submitUpdateReferral(formik.values) : submitReferral(formik.values);
                formik.resetForm();
                setModalOpen(false);
            } catch (error) {
                console.error('Error submitting referral:', error);
            } finally {
                setLoading(false);
            }
        } else {
            formik.setErrors(errors);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        formik.resetForm();
    };

    const submitReferral = async (values) => {
        try {
            const response = await apiCallFetch('/referrals/create', 'POST', values);
            if (response.status) {
                closeModal();
                alert(response.message)
                fetchReferrals();
            }
        } catch (err) {
            setFormError(err.response?.data?.message || 'An error occurred while submitting the referral.');
        }
    };

    const submitUpdateReferral = async (values) => {
        try {
            const response = await apiCallFetch('/referrals/update', 'PUT', values);
            if (response.status) {
                closeModal();
                alert(response.message)
                fetchReferrals();
            }
        } catch (err) {
            setFormError(err.response?.data?.message || 'An error occurred while submitting the referral.');
        }
    };

    const getStates = () => {
        const getAllStates = State.getStatesOfCountry('IN').map(state => ({
            value: state.name,
            displayValue: `${state.name} - ${state.isoCode}`
        }))
        setAllStates(getAllStates)
    }

    const getCity = (state) => {
        const getAllCities = City.getCitiesOfState('IN', state.split(' - ')[1])?.map(city => ({
            value: city.name,
            displayValue: city.name
        }))
        setAllCity(getAllCities)
    }

    const handleFileSubmit = async () => {
        setLoading(true);

        if (!file) {
            setLoading(false);
            return setFormError('No file selected');
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await apiCallFetch(
                '/referrals/bulk-upload',
                'POST',
                formData,
                {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
                    'Content-Type': 'multipart/form-data'
                }
            );

            if (response.status) {
                setFile(null)
                setFormError(null)
                setBulkModalOpen(false);
                fetchReferrals();
                alert(response.message)
            } else {
                setFormError(response.message || 'File upload failed')
            }
        } catch (error) {
            setFormError(error.response?.data?.message || 'File upload failed')
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async (ref) => {
        // setModalOpen(true);
        navigate('/add-referral', { state: { ref: ref } })
        setIsUpdate(true)
        getCity(ref.state);
        formik.setValues({ ...ref, category: ref.category?._id, referralId: ref._id });
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">My Gives ({totalReferal})</h1>

            <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setErr('');
                    }}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        setErr('');
                    }}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, i) => (
                        <option value={category._id} key={i}>{category.name}</option>
                    ))}
                </select>

                {/* <button
                    onClick={() => navigate('/add-bulk-referral')} // Navigate to the Add Bulk Referral page
                    className="bg-blue-500 text-white p-2 m-1 rounded flex items-center"
                >
                    <FaPlus className="mr-2" /> Add Bulk Referral
                </button> */}


                <button
                    onClick={() => navigate('/add-referral')} // Navigate to the desired route
                    className="bg-blue-500 text-white p-2 m-1 rounded flex items-center"
                >
                    <FaPlus className="mr-2" /> Add Give
                </button>

            </div>

            {initialLoading ? (
                <LoadingSpinner className="min-h-[200px] items-center" />
            ) : (
                <>
                    {err && <p>{err}</p>}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {referrals.map((referral, index) => (
                            <div
                                ref={index === referrals.length - 1 ? lastReferralElementRef : null}
                                key={referral._id}
                                className="bg-white p-4 rounded-xl shadow-lg flex flex-col relative"
                            >
                                <div className="absolute top-2 right-2 flex space-x-2">
                                    <button onClick={() => handleUpdate(referral)} className="text-gray-600 hover:text-blue-500">
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => handleDeleteReferral(referral._id)} className="text-gray-600 hover:text-red-500">
                                        <FaTrash />
                                    </button>
                                </div>
                                <h2 className="font-bold text-base text-gray-800 mb-2">
                                    {referral.name}
                                    <span className="font-normal text-xs text-gray-600"> ({referral?.category?.name})</span>
                                </h2>
                                <div className="grid grid-cols-2 gap-4 text-xs text-gray-600">
                                    <div className="flex items-center space-x-1">
                                        <FaBuilding className="text-blue-500" />
                                        <span>{referral.company}</span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaCity className="text-blue-500" />
                                        <span>{referral.city}, {referral.state}</span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaPhone className="text-blue-500" />
                                        <span>{referral.phone}</span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaBuilding className="text-blue-500" />
                                        <span>{referral.department}</span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <FaEnvelope className="text-blue-500" />
                                        <span>{referral.email}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4">
                        {loadingMore && <LoadingSpinner />}
                        {!hasMore && referrals.length > 0 && (
                            <p className="text-center text-gray-600">
                                No more referrals to load.
                            </p>
                        )}
                        {!initialLoading && referrals.length === 0 && (
                            <p className="text-center text-gray-600">
                                No referrals found.
                            </p>
                        )}
                    </div>
                </>
            )}

            {modalOpen && (
                <>
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-40"
                        onClick={() => setModalOpen(false)}
                    />

                    <div className="fixed inset-0 flex justify-center items-center z-50">

                        <div className="bg-white p-6 rounded shadow-md w-96 max-h-[90vh] overflow-y-auto">
                            <div className='flex justify-between mb-4'>

                                <h2 className="text-lg font-bold ">{isUpdate ? 'Update' : 'Add'}  Referral</h2>
                                <button
                                    className='text-end'
                                    onClick={() => setModalOpen(false)}
                                >X</button>
                            </div>

                            <form>
                                <input
                                    type="text"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placeholder="Name"
                                    className="border p-2 w-full mb-2"
                                />
                                {formik.errors.name && <div className="text-red-500 text-xs mb-2">{formik.errors.name}</div>}

                                <select
                                    name="category"
                                    value={formik.values.category}
                                    onChange={formik.handleChange}
                                    className="border p-2 w-full mb-2"
                                >
                                    <option value="">Select Category</option>
                                    {/* Replace with your categories data */}
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </select>
                                {formik.errors.category && <div className="text-red-500 text-xs mb-2">{formik.errors.category}</div>}

                                <input
                                    type="text"
                                    name="department"
                                    value={formik.values.department}
                                    onChange={formik.handleChange}
                                    placeholder="Department"
                                    className="border p-2 w-full mb-2"
                                />
                                {formik.errors.department && <div className="text-red-500 text-xs mb-2">{formik.errors.department}</div>}

                                <input
                                    type="text"
                                    name="company"
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    placeholder="Company"
                                    className="border p-2 w-full mb-2"
                                />
                                {formik.errors.company && <div className="text-red-500 text-xs mb-2">{formik.errors.company}</div>}

                                <select
                                    name="state"
                                    value={formik.values.state}
                                    onChange={(event) => {
                                        console.log(event.target.value, "this is event")
                                        formik.handleChange(event);
                                        getCity(event.target.value);
                                    }}
                                    className="border p-2 w-full mb-2"
                                >
                                    <option value="" label="Select State" />

                                    {allStates.map((state, i) => (
                                        <option key={i} value={state.displayValue}>
                                            {state.displayValue}
                                        </option>
                                    ))}
                                </select>

                                {formik.errors.state && (
                                    <div className="text-red-500 text-xs mb-2">{formik.errors.state}</div>
                                )}

                                <select
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    className="border p-2 w-full mb-2"
                                >
                                    <option value="" label="Select City" />

                                    {allCity.map((city, i) => (
                                        <option key={i} value={city.value}>
                                            {city.value}
                                        </option>
                                    ))}
                                </select>
                                {formik.errors.city && (
                                    <div className="text-red-500 text-xs mb-2">{formik.errors.city}</div>
                                )}

                                <input
                                    type="text"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    placeholder="Phone"
                                    className="border p-2 w-full mb-2"
                                />
                                {formik.errors.phone && <div className="text-red-500 text-xs mb-2">{formik.errors.phone}</div>}

                                <input
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder="Email"
                                    className="border p-2 w-full mb-2"
                                />
                                {formik.errors.email && <div className="text-red-500 text-xs mb-2">{formik.errors.email}</div>}

                                {formErr && <p className='text-red-500'>{formErr}</p>}
                                <button
                                    type="button"
                                    className="bg-blue-500 text-white py-2 px-4 rounded w-full"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Loading...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>

                </>
            )}

            {bulkModalOpen && (
                <>

                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-40"
                        onClick={() => setBulkModalOpen(false)}
                    />

                    <div className="fixed inset-0 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded shadow-md w-96 max-h-[90vh] overflow-y-auto">
                            <div className='flex justify-between mb-4'>

                                <h2 className="text-lg font-bold ">{'Add Bulk Referral'}</h2>
                                <button
                                    className='text-end'
                                    onClick={() => setBulkModalOpen(false)}
                                >X</button>
                            </div>

                            <form>

                                <div className="mb-4">
                                    <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700 mb-2">
                                        Select Excel File
                                    </label>
                                    <div className="flex items-center justify-center w-full">
                                        <label
                                            htmlFor="file-upload"
                                            className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                        >
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <FaFileExcel className="w-10 h-10 mb-3 text-gray-400" />
                                                <p className="mb-2 text-sm text-gray-500">
                                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                                <p className="text-xs text-gray-500">Excel file (XLSX)</p>
                                            </div>
                                            <input id="file-upload" type="file" className="hidden" onChange={(e) => {
                                                setFile(e.target.files[0])
                                                setFormError('')
                                            }} accept=".xlsx" />
                                        </label>
                                    </div>
                                </div>
                                {file && (
                                    <p className="text-sm text-gray-600 mb-4">
                                        Selected file: {file.name}
                                    </p>
                                )}

                                {formErr && <p className='text-sm text-red-500'>{formErr}</p>}

                                <button
                                    type="button"
                                    className="bg-blue-500 text-white py-2 px-4 rounded w-full"
                                    onClick={handleFileSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Loading...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

export default MyReferrals;
