import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function CompanyQuiz() {
    const [quizData, setQuizData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedQuizzes, setExpandedQuizzes] = useState({});
    const navigate = useNavigate();
    const companyId = JSON.parse(localStorage.getItem("company"))._id;

    useEffect(() => {
        const fetchQuizData = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch(`/quiz/quiz-by-company/${companyId}?page=1&limit=20&sortBy=name&sortOrder=asc`, 'GET');
                setQuizData(response.data.quizzes || []);
            } catch (err) {
                setError('Failed to load quiz data');
                console.error('API Error:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchQuizData();
    }, [companyId]);

    const toggleQuizExpansion = (quizId) => {
        setExpandedQuizzes((prevExpanded) => ({
            ...prevExpanded,
            [quizId]: !prevExpanded[quizId],
        }));
    };

    const handleEdit = (quiz) => {
        navigate(`/edit-quiz/${quiz._id}`);
    };

    const handleDelete = async (quizId) => {
        if (window.confirm("Are you sure you want to delete this quiz?")) {
            try {
                await apiCallFetch(`/quiz/delete/${quizId}`, 'PUT');
                setQuizData(quizData.filter(quiz => quiz._id !== quizId));
                alert("Quiz deleted successfully!");
            } catch (err) {
                console.error("Error deleting quiz:", err);
                setError("Failed to delete the quiz.");
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="loader"></div>
                <p className="text-lg text-gray-500">Loading quizzes...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-2xl font-bold">Company Quizzes</h1>
                {/* <button
                    onClick={() => navigate('/add-quiz')}
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition flex items-center"
                >
                    <FaPlus className="mr-2" /> Add Quiz
                </button> */}
            </div>

            {quizData.length > 0 ? (
                quizData.map((quiz) => (
                    <div key={quiz._id} className="mb-6 p-6 border border-gray-300 rounded-lg shadow-md bg-white relative">
                        <h2 className="text-2xl font-semibold text-gray-800">{quiz.name}</h2>
                        <p className="text-gray-600 mt-2">{quiz.description}</p>

                        {/* <div className="absolute top-2 right-2 flex space-x-2">
                            <button onClick={() => handleEdit(quiz)} className="text-blue-500 hover:text-blue-700">
                                <FaEdit />
                            </button>
                            <button onClick={() => handleDelete(quiz._id)} className="text-red-500 hover:text-red-700">
                                <FaTrash />
                            </button>
                        </div> */}

                        <button
                            onClick={() => toggleQuizExpansion(quiz._id)}
                            className="mt-4 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                        >
                            {expandedQuizzes[quiz._id] ? 'Hide Questions' : 'Show Questions'}
                        </button>

                        {expandedQuizzes[quiz._id] && (
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold text-blue-600">Questions:</h3>
                                {quiz.questions.map((question, index) => (
                                    <div key={question._id} className="mt-4 bg-gray-50 p-4 rounded-lg shadow-sm">
                                        <p className="text-gray-800">
                                            <strong>Q{index + 1}:</strong> {question.question.question}
                                        </p>
                                        <ul className="list-decimal list-inside ml-6 mt-2">
                                            {question.question.options.map((option) => (
                                                <li
                                                    key={option._id}
                                                    className={`my-1 ${option.isCorrect ? 'text-green-600 font-semibold' : 'text-gray-700'}`}
                                                >
                                                    {option.text} {option.isCorrect ? '(Correct)' : ''}
                                                </li>
                                            ))}
                                        </ul>
                                        <p className="text-gray-500 mt-2 text-sm">Explanation: {question.question.explanation}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <p className="text-center text-gray-500">No quizzes available for this company.</p>
            )}
        </div>
    );
}

export default CompanyQuiz;
